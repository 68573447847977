export default {
  siteTitle: 'Get bonus',
  blogerName: 'Renato',
  socialsList: [
    {
      name: 'telegram',
      url: 'https://t.me/+kj8BDDp9IFgxNjU5'
    },
    {
      name: 'instagram',
      url: 'https://www.instagram.com/renatodocorte100x/'
    }
  ],
  projects: [
    {
      name: '1go',
      url: 'https://1gocasino.life/ceb295a08',
      gameTitle: 'Book of Kemet (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'monro',
      url: 'https://monrocasino.life/cb535d341',
      gameTitle: 'Savage Buffalo Spirit Megaways',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'drip',
      url: 'https://dripcasino.life/c60e3cb15',
      gameTitle: 'Alien Fruits (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'jet',
      url: 'https://jetcasino.life/cb8f3ac9f',
      gameTitle: 'JET AIR (BGaming)',
    },
    {
      name: 'izzi',
      url: 'https://izzicasino.life/c55fd6ea3',
      gameTitle: 'Izzi Art (BGaming)',
    },
    {
      name: 'fresh',
      url: 'https://freshcasino.life/c30285c7f',
      gameTitle: 'Doors Of Fresh (BGaming)',
    },
    {
      name: 'sol',
      url: 'https://solcasino.life/c14ff546e',
      gameTitle: 'Doors Of Sol (BGaming)',
    },
    {
      name: 'starda',
      url: 'https://stardacasino.life/c72b86191',
      gameTitle: 'Starda Queen (BGaming)',
    },
    {
      name: 'legzo',
      url: 'https://legzocasino.life/cd7195265',
      gameTitle: 'Legzo Punk (BGaming)',
    }
  ],
  headerText: 'Cadastre-se com um código promocional <strong>1000X</strong> e receba 50 rodadas grátis nos jogos abaixo',
  yourPromocode: 'Seu código promocional',
  gameBonus: 'Bónus no jogo',
  promocode: '1000X',
  casinoBonus1: '<strong>100%</strong><div>No primeiro depósito</div>',
  casinoBonus2: '<strong>50FS</strong><div>Para cadastro com código promocional</div>',
  firstDeposit: 'DE PRIMEIRO DEPÓSITO',
  bonusesText: 'GIROS GRATIS',
  copyMessage: 'Código promocional copiado',
  theme: 'dark',
};
